import React from 'react';
import Icon, { GlyphNames } from './icon';
import { Link } from 'gatsby';
import { useAdminUser } from '../hooks/adminUser';
import { goBack } from '../lib/navigation';
import { getResourceText } from '../lib/language';

const AdminPage = ({ children, withBack = true }) => {
  const { username, organisationName } = useAdminUser();

  return (
    <div className="admin-page-container">
      <div className="admin-page-container__header">
        <div className="admin-page-container__header-nav-left">
          {withBack ?
            <button onClick={goBack} className="admin-page-container__back-link">
              <Icon glyphName={GlyphNames.leftArrow}/><span className="admin-page-container__back-link-text">back</span>
            </button> : null}
        </div>
        <div className="admin-page-container__header-title-container">
          <h1 className="admin-page-container__header-title">{getResourceText('admin')}</h1>
        </div>
        <div className="admin-page-container__header-user-container">
          <span className="admin-page-container__header-user-container-item">{username}</span>
          {organisationName ? <span className="admin-page-container__header-user-container-item">{organisationName}</span> : null}
          <Link className="admin-page-container-link" to="/login">{getResourceText('logout')}</Link>
        </div>
      </div>
      <nav className="admin-page-container__nav">
        <ul className="admin-page-container__nav-links">
          <li className="admin-page-container__nav-link-container">
            <Link className="admin-page-container__nav-link" to="/admin/resource-packs">{getResourceText('resourcePacks')}</Link>
          </li>
          <li className="admin-page-container__nav-link-container">
            <Link className="admin-page-container__nav-link" to="/admin/users">{getResourceText('users')}</Link>
          </li>
          <li className="admin-page-container__nav-link-container">
            <Link className="admin-page-container__nav-link" to="/admin/subscriptions">{getResourceText('subscriptions')}</Link>
          </li>
          <li className="admin-page-container__nav-link-container">
            <Link className="admin-page-container__nav-link" to="/admin/organisations">{getResourceText('organisations')}</Link>
          </li>
        </ul>
      </nav>
      <div className="admin-page-body">
        {children}
      </div>
    </div>
  );
};

export default AdminPage;