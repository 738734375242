import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { getData, USER_KEY } from '../lib/store';

export const useAdminUser = () => {
  const user = getData(USER_KEY);

  useEffect(() => {
    if (!user || !user.isSystemAdmin) {
      navigate('/login');
    }
  }, [user]);

  return user ?? {};
};

