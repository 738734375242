import React, {FC} from 'react';
import classNames from 'classnames';

export const GlyphNames = {
  close: 'close',
  leftArrow: 'leftArrow',
  plus: 'plus',
  tick: 'tick',
  cross: 'cross',
  copy: 'copy',
  calendar: 'calendar',
};

export const Glyphs = {
  [GlyphNames.close]: '\ue900',
  [GlyphNames.leftArrow]: '\ue901',
  [GlyphNames.plus]: '\ue903',
  [GlyphNames.tick]: '\ue905',
  [GlyphNames.cross]: '\ue904',
  [GlyphNames.copy]: '\ue906',
  [GlyphNames.calendar]: '\ue907',
};

const Icon: FC<Props> = ({ glyphName, css }) => (
  <span className={classNames('icon', css)}>{Glyphs[glyphName]}</span>
);

type Props = {
  glyphName: GlyphName;
  css?: string;
};

type GlyphName = 'close' | 'leftArrow' | 'plus' | 'tick' | 'cross' | 'copy' | 'calendar';

export default Icon;
